









































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  TitleTemplateDashboard,
  SelectedCountry,
  RedCapital,
} from "@/configuration";
import DniPerson from "@/components/DniField.vue";
import { mapState } from "vuex";
import API from "@/api";
import CustomSnackBar from "@/components/CustomSnackBar.vue";

// @ts-ignore
import ChartJS from "chart.js";
import { EventBus } from "@/main";
import Axios from "axios";
var moment = require("moment");

@Component({
  metaInfo: {
    title: "Ceder facturas",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  computed: {
    ...mapState(["user", "setOpenMenuPanel"]),
    uploadDisabled() {
      return this.$data.files.length === 0;
    },
    setOpenMenuPanel() {
      return this.$store.state.openMenuPanel;
    },
  },
  data() {
    return {
      isMobile: false,
      certificado2: false,
      businessS: false,
      certificado: false,
      validFormCertificado: true,
      isformValid2: false,
      formCert: {
        password_certidicado: "",
      },
      dialogConfirm: false,
      msjSuccess: "",
      canShow: false,
      tipoSnack: "",
      msjeSnack: "",
      isActiveSnackBar: false,
      hiddenDiv: true,
      mensajeError: "",
      imgError: "",
      alertErrorCertificado: false,
      showPassword: false,
      pfx: [],
      clave: null,
      date: "",
      date2: "",
      steps: 1,
      datos: false,
      dateFormatted: null,
      dateFormatted2: null,
      pagination: {
        sortBy: "name",
      },
      errores: [],
      selected: [],
      titlebtn: "Seleccionar empresa",
      cheque: false,
      num_factur: 0,
      total_factur: 0,
      business: null,
      dialog2: null,
      dialog3: null,
      staticBusiness: "",
      selectedB: "",
      files: [],
      filesxls: [],
      errors: null,
      dialog: false,
      tabs: null,
      password: "",
      c_password: "",
      isReady: false,
      montos: {},
      contenido: true,
      contanidovinculado: true,
      context: null,
      chart: null,
      facturas_subidas: [],
      facturas_ceder: [],
      datos_salida: null,
      lista_parametros_cotizacion: {
        empresa_id: null,
        tasa_all_in: null,
        tir: "",
        tasa: "",
        dias_separacion: "",
        anticipo: "",
        gastos_notariales: "",
        monto_minimo_proyecto: "",
        monto_maximo_proyecto: "",
        facturas: [],
      },
      headers: [
        {
          text: "#",
          align: "center",
          sortable: false,
        },
        {
          text: "Tipo",
          align: "center",
          sortable: false,
        },
        {
          text: "Rut receptor",
          align: "center",
          sortable: false,
        },
        {
          text: "Folio",
          align: "center",
          sortable: false,
        },
        {
          text: "Fecha emisión",
          align: "center",
          sortable: false,
        },
        {
          text: "Fecha vencimiento",
          align: "center",
          sortable: false,
        },
        {
          text: "Monto",
          align: "center",
          sortable: false,
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
        },
      ],
      headers2: [
        {
          text: "Tipo",
          align: "center",
          sortable: false,
        },
        {
          text: "Rut receptor",
          align: "center",
          sortable: false,
        },
        {
          text: "Folio",
          align: "center",
          sortable: false,
        },
        {
          text: "Fecha emisión",
          align: "center",
          sortable: false,
        },
        {
          text: "Fecha pago",
          align: "center",
          sortable: false,
        },
        {
          text: "Monto",
          align: "center",
          sortable: false,
        },
        {
          text: "Nº seguimiento",
          align: "center",
          sortable: false,
        },
        {
          text: "Acciones",
          align: "center",
          sortable: false,
        },
        {
          text: "Cargar",
          align: "center",
          sortable: false,
        },
      ],
      rules: {
        notEmpty: [(v: any) => !!v || "Ingrese un valor"],
        password: [(v: any) => !!v || "La contraseña es requerida"],
      },
    };
  },
  beforeMount() {
    if (this.$store.state.businesSelected != null) {
      API.factoring
        .listarFacturasParaCeder(this.$store.state.businesSelected.id)
        .then((res: any) => {
          this.$data.datos = true;
          this.$data.contenido = false;
          this.$data.facturas_subidas = res.data;
          // res.data.data.forEach((el:any) => {
          //   this.$data.facturas_subidas = res.data;
          // })
        })
        .catch((e) => {
          this.$data.datos = true;
          this.$data.contenido = false;
          let errors = e.response.data.message;

          if (Object.keys(errors)[0] === "error") {
            //   EventBus.$emit('flash-message', { text:  errors[Object.keys(errors)[0]][0], 'type': 'error' })
          }
        });
      API.factoring
        .lista_parametros_cotizacion()
        .then((res: any) => {
          this.$data.lista_parametros_cotizacion = res.data;
        })
        .catch((err: any) => {});
    } else {
      this.$data.datos = true;
        API.getBusiness()
        .then((res: any) => {
          this.$data.staticBusiness = res;
          this.$data.staticBusiness.unshift({ nombre: "CREAR EMPRESA", id: 0 });
          if (res.length == 0) {
            this.$data.dialog3 = true;
          }
        })
        .catch((err: any) => {});
    }

  },
  mounted() {
    // @ts-ignore
    this.onResize();
    // @ts-ignore
    window.addEventListener("resize", this.onResize);
  },
  watch: {
    "$store.state.businesSelected": {
      handler() {
        if (this.$store.state.businesSelected != null) {
          this.$data.canShow = true;
        } else {
          this.$data.canShow = false;
        }
      },
      immediate: true,
      deep: true,
    },
    selected(v) {
      if (v.length > 0) {
        this.$data.num_factur = v.length;
        this.$data.total_factur = v
          .map((monto: any) => {
            return parseInt(monto.monto);
          })
          .reduce((a: any, b: any) => {
            return parseInt(a) + parseInt(b);
          });

        var fact: any = [];
        v.forEach((el: any) => {
          let idfac = { id: "" };
          idfac.id = el.id;
          fact.push(idfac);
        });
        this.$data.lista_parametros_cotizacion.facturas = fact;
      } else {
        this.$data.num_factur = 0;
        this.$data.total_factur = 0;
      }
    },
    business(v) {
      this.$data.staticBusiness.forEach((el: any, i: any) => {
        el.id == v ? (this.$data.selectedB = el) : "";
      });
      v == 0
        ? (this.$data.titlebtn = "Crear")
        : (this.$data.titlebtn = "Seleccionar empresa");
    },
  },
  methods: {
    changeStateSnackbar(value) {
      this.$data.isActiveSnackBar = value;
      this.$data.tipoSnack = "";
      this.$data.msjeSnack = "";
    },
    bussinessSelection() {
      // API.getBusiness2(this.$store.state.user.id).then((res: any) => {
      //   this.$data.businessData = res;
      //   if (this.$data.businessData.length == 0) {
      //     this.$router.push("/panel-facto/empresas");
      //     setTimeout(() => {
      //       EventBus.$emit("empresa", true);
      //     }, 1000);
      //   } else if (this.$data.businessData.length == 1) {
      //     this.$store.commit("setBusinessSelected", this.$data.businessData[0]);
      //   } else if (this.$data.businessData.length > 1) {
      //     if (this.$store.state.businesSelected == null) {
      //       setTimeout(() => {
      //         EventBus.$emit("multibusiness", true);
      //       }, 1000);
      //     }
      //   }
      // });
    },
    onResize() {
      if (window.innerWidth < 1263) this.$data.isMobile = true;
      else this.$data.isMobile = false;
    },
    addpfx() {
      // @ts-ignore
      this.$refs.uploadpfx.click();
    },
    fileUploadpfx(event) {
      if (event.target.files.length > 0) {
        // @ts-ignore
        var f = event.target.files[0];
        this.$data.pfx.push(f);
      }
    },
    fileUploadxml(event) {
      if (event.target.files.length > 0) {
        let filess = event.target.files;
        if (!filess) return;
        [...filess].forEach((f) => {
          this.$data.files.push(f);
        });
      }
    },
    addxml() {
      // @ts-ignore
      this.$refs.uploadxml.click();
    },
    addFile2(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        this.$data.pfx.push(f);
      });
    },
    removeFile2(file, event) {
      event.preventDefault();
      this.$data.pfx = this.$data.pfx.filter((f: any) => {
        return f != file;
      });
    },
    fileUpload() {
      // this.$data.dialog = true
      // @ts-ignore
      if (this.$refs.pdf.files.length > 0) {
        // @ts-ignore
        this.$data.currentFolder = this.$refs.pdf.files[0];
        let formData = new FormData();
        formData.append("carpeta", this.$data.currentFolder);
      }
    },
    toggleAll() {
      if (this.$data.selected.length) {
        this.$data.selected = [];
      } else {
        this.$data.selected = this.$data.facturas_subidas.slice();
      }
    },
    toggleAll2() {
      if (this.$data.selected2.length) {
        this.$data.selected2 = [];
      } else {
        this.$data.selected2 = this.$data.selected.slice();
      }
    },
    changeSort(column) {
      if (this.$data.pagination.sortBy === column) {
        this.$data.pagination.descending = !this.$data.pagination.descending;
      } else {
        this.$data.pagination.sortBy = column;
        this.$data.pagination.descending = false;
      }
    },
    async sincronizar_emitidos() {
      await API.factoring
        .sincronizar_emitidos(this.$store.state.businesSelected.rut)
        .then((res: any) => {
          if (res.errors == false) {
            EventBus.$emit("snack-success", res.data.message);
            API.factoring
              .listarFacturasCargadas(this.$store.state.businesSelected.id)
              .then((res: any) => {
                this.$data.facturas_subidas = res.data.data;
              })
              .catch((e) => {
                let errors = e.response.data.message;
                if (Object.keys(errors)[0] === "error") {
                  //   EventBus.$emit('flash-message', { text:  errors[Object.keys(errors)[0]][0], 'type': 'error' })
                }
              });
          }
        })
        .catch((err: any) => {});
    },
    actualizartabla() {
      API.factoring
        .listarFacturasCargadas(this.$store.state.businesSelected.id)
        .then((res: any) => {
          this.$data.facturas_subidas = res.data.data;
        })
        .catch((e) => {
          let errors = e.response.data.message;
          if (Object.keys(errors)[0] === "error") {
            //   EventBus.$emit('flash-message', { text:  errors[Object.keys(errors)[0]][0], 'type': 'error' })
          }
        });
    },
    cambiarSelected() {
      this.$store.commit("setBusinessSelected", null);
    },
    selectedBusiness() {
      // this.$data.business == 0
      //   ? this.$router.push("/panel-facto/createbussiness")
      //   : this.$store.commit("setBusinessSelected", this.$data.selectedB);
      if (this.$data.businesss == 0) {
        this.$router.push("/panel-facto/createbussiness");
      } else {
        this.$store.commit("setBusinessSelected", this.$data.selectedB);
        this.$data.multibusiness = false;
      }
    },
    cargar() {
      this.$data.contenido = false;
    },
    addFile(e) {
      let droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      [...droppedFiles].forEach((f) => {
        this.$data.files.push(f);
      });
    },
    removeFile(file, event) {
      event.preventDefault();
      this.$data.files = this.$data.files.filter((f: any) => {
        return f != file;
      });
    },
    upload() {
      this.$data.dialog = true;
      if (this.$store.state.businesSelected != null) {
        API.factoring
          .factureUploads(
            this.$store.state.businesSelected.id,
            this.$data.files
          )
          .then((res: any) => {
            if (res.data.facturas_creadas.length > 0) {
              //@ts-ignore
              this.actualizartabla();
              EventBus.$emit(
                "alerts",
                true,
                "¡Atención!",
                "Facturas cargadas correctamente"
              );
              EventBus.$emit("snack-success", "Factura cargada con exito");
              this.$data.dialog = false;
            } else {
              EventBus.$emit(
                "alertsError",
                true,
                "¡Atención!",
                "Las facturas seleccionadas no han podido ser cargadas porque ya se encuentran en nuestro sistema, por favor intenta con otras facturas."
              );
              EventBus.$emit("snack-error", "Error al cargar la Factura");
              this.$data.dialog = false;
            }
          })
          .catch((err) => {
            var error;
            if (err.response.data.message.xml[0]) {
              error = err.response.data.message.xml[0];
            }
            this.$data.dialog = false;
            EventBus.$emit("alertsError", true, "¡Atención!", `${error}`);
            EventBus.$emit("snack-error", "Error al cargar la Factura");
          });
      } else {
        EventBus.$emit(
          "alerts",
          true,
          "¡Atención!",
          "Debe seleccionar una Empresa"
        );
        EventBus.$emit("snack-error", "Debe seleccionar una Empresa");
        this.$data.dialog = false;
      }
    },
    ceder(factura) {
      this.$data.dialog = true;
      var facturas_ceder: any[] = [];
      let idfac = {
        id: "",
      };
      idfac.id = factura.id;
      facturas_ceder.push(idfac);
      API.factoring
        .cederFacturasMasivo(
          this.$store.state.businesSelected.id,
          facturas_ceder
        )
        .then((res: any) => {
          if (res[0].error) {
            EventBus.$emit(
              "alertsError",
              true,
              "¡Atención!",
              `Error al ceder Facturas Folio: ${res[0].facturas_con_error.join(
                ", "
              )}`
            );
          } else {
            EventBus.$emit(
              "alerts",
              true,
              "¡Atención!",
              "Facturas cargadas correctamente"
            );
          }
          this.$data.dialog = false;
        })
        .catch((err: any) => {
          EventBus.$emit(
            "alertsError",
            true,
            "¡Atención!",
            "Error al cargar Facturas"
          );
          this.$data.dialog = false;
        });
      // this.$data.dialog = true;
      // if (this.$store.state.businesSelected != null) {
      //   API.factoring
      //     .cederFacture(this.$store.state.businesSelected.id, id)
      //     .then((result) => {
      //       //@ts-ignore
      //       this.actualizartabla();
      //       EventBus.$emit("snack-success", "Factura cargada con exito");
      //       this.$data.dialog = false;
      //     })
      //     .catch((err) => {
      //       let error = err
      //       console.log(error)
      //       this.$data.dialog = false;
      //     });
      // } else {
      //   EventBus.$emit("snack-error", "Debe seleccionar una Empresa");
      //   this.$data.dialog = false;
      // }
    },
    cederFacturasMasivo() {
      this.$data.dialog = true;
      this.$data.facturas_ceder = [];
      this.$data.selected.forEach((el: any) => {
        let idfac = {
          id: "",
        };
        idfac.id = el.id;
        this.$data.facturas_ceder.push(idfac);
      });
      API.factoring
        .cederFacturasMasivo(
          this.$store.state.businesSelected.id,
          this.$data.facturas_ceder
        )
        .then((res: any) => {
          if (res[0].error) {
            EventBus.$emit(
              "alertsError",
              true,
              "¡Atención!",
              `Error al ceder Facturas Folio: ${res[0].facturas_con_error.join(
                ", "
              )}`
            );
          } else {
            EventBus.$emit(
              "alerts",
              true,
              "¡Atención!",
              "Facturas cargadas correctamente"
            );
          }
          this.$data.dialog = false;
        })
        .catch((err: any) => {
          EventBus.$emit(
            "alertsError",
            true,
            "¡Atención!",
            "Error al cargar Facturas"
          );
          this.$data.dialog = false;
        });
    },
  },
  components: {
    DniPerson,
    CustomSnackBar,
  },
})
export default class FactureCeder extends Vue {}
