






















import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "@/main";
@Component({
    props:{
        text: String,
        tipo: String, //success, error
        alerts: Boolean
    },
    data(){
        return{
            text: this.$props.text,
            color:"",
            icon:"",
            icon_style: "",
            color_font: "",
            alerts: this.$props.alerts
        }
    },
    watch:{
        "$props.tipo": {
            handler(){
                if(this.$props.tipo == "success"){
                    // @ts-ignore
                    this.isSuccess();
                }
                else if( this.$props.tipo == "error"){
                    // @ts-ignore
                    this.isFailed();
                }
            },
            deep: true,
            immediate: true
        },
    },
    methods:{
        isSuccess(){
            this.$data.color = "#E0FFF9";
            this.$data.icon = "check_circle"
            this.$data.icon_style = "margin-left: 20px; color: #00d1a8"
            this.$data.color_font ="color: #00d1a8; text-transform: none"
        },
        isFailed(){
            this.$data.color = "#E25A43";
            this.$data.icon = "highlight_off"
            this.$data.icon_style = "margin-left: 20px; color: white"
            this.$data.color_font ="color: white; text-transform: none"
        },
        close(){
            this.$data.alerts = false
            this.$emit("changeState", this.$data.alerts)
        }
    }
})
export default class CustomSnackBar extends Vue{}
