
















































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  TitleTemplateDashboard,
  SelectedCountry,
  RedCapital,
} from "@/configuration";
import DniPerson from "@/components/DniField.vue";
import { mapState } from "vuex";
import API from "@/api";
// @ts-ignore
import ChartJS from "chart.js";
import { EventBus } from "@/main";
import Axios from "axios";
var moment = require("moment");

@Component({
  metaInfo: {
    title: "Estadísticas",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  computed: {
    ...mapState(["user"]),
    uploadDisabled() {
      return this.$data.files.length === 0;
    },
  },
  data() {
    return {
      email_switch: this.$store.state.siiConfigurations.mail.enabled,
      phone_switch: this.$store.state.siiConfigurations.whatsapp.enabled,
      oferta_switch: this.$store.state.siiConfigurations.news.enabled,
      email: this.$store.state.user.email,
      phone: this.$store.state.user.telefono,
      oferta: this.$store.state.user.email,
      rules: {
        notEmpty: [(v: any) => !!v || "Ingrese un valor"],
        password: [(v: any) => !!v || "La contraseña es requerida"],
      },
    };
  },

  methods: {
    guardar() {
      this.$data.dialog = true;
      let mail = this.$data.email_switch == false ? 1 : 2;
      let whatsapp = this.$data.phone_switch == false ? 1 : 2;
      let news = this.$data.oferta_switch == false ? 1 : 2;
      API.factoring
        .guardar_actualizar_notificacion(mail, whatsapp, news)
        .then((res: any) => {
          EventBus.$emit("snack-success", "Datos actualizados correctamente");
          this.$data.dialog = false;
          API.factoring
            .get_notificaciones()
            .then((res: any) => {
              this.$store.commit("setsiiConfigurations", res.data);
            })
            .catch((err: any) => {
            });
        })
        .catch((error) => {

        });
    },
  },
  components: {
    DniPerson,
  },
})
export default class Notification extends Vue {}
