


































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  TitleTemplateDashboard,
  SelectedCountry,
  RedCapital,
} from "@/configuration";
import { mapState } from "vuex";
import API from "@/api";
import { EventBus } from "@/main";
import ShowLoading from "@/components/panel/ShowLoading.vue";

@Component({
  metaInfo: {
    title: "Mis claves",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang,
    },
  },
  computed: {
    ...mapState(["user","setOpenMenuPanel", "businesSelected", "estado_sunat"]),
      setOpenMenuPanel(){
				return this.$store.state.openMenuPanel;
			},
      setBorderClass(){
        //@ts-ignore
        if(this.estado_sunat == 1){
          return 'bordeGreen';
        }
        //@ts-ignore
        else if(this.estado_sunat == 2){
          return 'bordeYellow'
        }
        else{
          return 'bordeRed';
        }
      },
  },
  watch:{
    "$store.state.businesSelected": {
      handler(){
        if(this.$store.state.businesSelected != null){
          this.$data.canShow = true;
        }
        else{
          this.$data.canShow = false;
        }
      },
      immediate: true,
      deep:true
				
    },
    formSunat: {
      handler(val){
        // @ts-ignore
        if(this.$refs.form.validate()){
          if(this.$data.formSunat.usuario_sunat.length < 8 && this.$data.formSunat.clave_sol.length < 6){
            this.$data.isformValid = false;
          }
          else{
            this.$data.isformValid = true;
          }
        }
        else{
          this.$data.isformValid = false;
        }
      },
      deep:true,
      
    },
  },
  data() {
    return {
        formSunat:{
          dni: null,
          usuario_sunat: null,
          clave_sol: null,
        },
        vincular:false, //variable para ver si esta vinculado o no -> le da los colores a la card
        mensajeError: "",
        imgError: "",
        msjSuccess:"",
        tipoSnack:"",
        msjeSnack: "",
        isActiveSnackBar: false,
        valid: true,
        isformValid: false,
        clave: false,
        showPassword: false,
        dialog:false,
        staticBusiness: null,
        rules: {
            notEmpty: [(v: any) => !!v || "Ingrese un valor"],
            password: [
              (v: any) => !!v || "La contraseña es requerida",
              (v: any) => v.length >= 6 || "Mínimo 6 caracteres",
            ],
            usuario: [
              (v: any) => !!v || "El usuario es requerido",
              (v: any) => v.length >= 8 || "Mínimo 8 caracteres",

            ]
        },
        canShow:false,
        btnLoading: false,
    }
  },
  components:{
    ShowLoading,
  },
  async beforeMount(){
    API.getBusiness()
      .then((res: any) => {
        this.$data.staticBusiness = res;
        this.$data.staticBusiness.unshift({ nombre: "CREAR EMPRESA", id: 0 });
      })
      .catch((err: any) => {
      });
    
    this.$data.canShow = true;
  },
  methods: {
    openClaveSUNAT(){
      if (this.$store.state.businesSelected != null) {
          this.$data.formSunat.dni = this.$store.state.businesSelected.nombre;
      }

      this.$data.clave = true;
    },
    loginSunat(){
      const api_key = 'key_onboarding_prod_fnPqT5xQEi5Vcb9wKwbCf65c3BjVGyBB';
      //@ts-ignore
      const empresa_ruc = this.businesSelected.rut;
      this.$data.btnLoading = true;
      API.factoring.loginSunat(
        empresa_ruc, 
        this.$data.formSunat.usuario_sunat, 
        this.$data.formSunat.clave_sol,
        api_key).then((response) => {
          let res = response.data;
          if(res.status === 200){
            EventBus.$emit("snack-success", res.message);
            this.$store.commit("setEstadoSunat", 2);
          }
        }).catch((e) => {
          EventBus.$emit("snack-error", e.message);
      }).finally(() => {
        this.$data.btnLoading = false;
        this.$data.clave = false;
      })

    }
  }
})
export default class NewPassSunat extends Vue {}
