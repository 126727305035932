























































































































































































































































  import { Component, Vue } from "vue-property-decorator";
  import {
    TitleTemplateDashboard,
    SelectedCountry,
    RedCapital,
  } from "@/configuration";
  import { mapState } from "vuex";
  import API from "@/api";
  import { EventBus } from "@/main";
  import ShowLoading from "@/components/panel/ShowLoading.vue";
import BusinessSelect from '../../../components/dashboard/BusinessSelect.vue';
  
  @Component({
    metaInfo: {
      title: "Mis claves",
      titleTemplate: TitleTemplateDashboard,
      htmlAttrs: {
        // @ts-ignore
        lang: RedCapital[SelectedCountry].Lang,
      },
    },
    computed: {
      ...mapState(["user","setOpenMenuPanel", "businesSelected", "estado_cavali"]),
        setOpenMenuPanel(){
                  return this.$store.state.openMenuPanel;
              },
        setBorderClass(){
          //@ts-ignore
          if(this.estado_cavali == 1){
            return 'bordeGreen';
          }
          //@ts-ignore
          else if(this.estado_cavali == 2){
            return 'bordeYellow'
          }
          else{
            return 'bordeRed';
          }
        },
    },
    watch:{
      "$store.state.businesSelected": {
        handler(){
          if(this.$store.state.businesSelected != null){
            this.$data.canShow = true;
          }
          else{
            this.$data.canShow = false;
          }
        },
        immediate: true,
        deep:true
                  
      },
      formCavali: {
        handler(val){
          // @ts-ignore
          if(this.$refs.form.validate()){
            if(this.$data.formCavali.usuario_cavali.length < 8 && this.$data.formCavali.clave_cavali.length < 6){
              this.$data.isformValid = false;
            }
            else{
              this.$data.isformValid = true;
            }
          }
          else{
            this.$data.isformValid = false;
          }
        },
        deep:true,
        
      },
    },
    data() {
      return {
          formCavali:{
            usuario_cavali: null,
            clave_cavali: null,
          },
          vincular:false, //variable para ver si esta vinculado o no -> le da los colores a la card
          mensajeError: "",
          imgError: "",
          msjSuccess:"",
          tipoSnack:"",
          msjeSnack: "",
          isActiveSnackBar: false,
          valid: true,
          isformValid: false,
          clave: false,
          showPassword: false,
          dialog:false,
          staticBusiness: null,
          rules: {
              notEmpty: [(v: any) => !!v || "Ingrese un valor"],
              password: [
                (v: any) => !!v || "La contraseña es requerida",
              ],
              usuario: [
                (v: any) => !!v || "El usuario es requerido",
              ]
          },
          canShow:false,
          btnLoading: false,
      }
    },
    components:{
      ShowLoading,
    },
    async beforeMount(){
      API.getBusiness()
        .then((res: any) => {
          this.$data.staticBusiness = res;
          this.$data.staticBusiness.unshift({ nombre: "CREAR EMPRESA", id: 0 });
        })
        .catch((err: any) => {
        });
      
      this.$data.canShow = true;
    },
    methods: {
      openClaveCAVALI(){
        if (this.$store.state.businesSelected != null) {
            this.$data.formCavali.dni = this.$store.state.businesSelected.nombre;
        }
  
        this.$data.clave = true;
      },
      loginCavali(){
        if(this.$store.state.businesSelected != null){
          const api_key = 'key_onboarding_prod_fnPqT5xQEi5Vcb9wKwbCf65c3BjVGyBB';
          //@ts-ignore
          const empresa_ruc = this.businesSelected.rut;
          this.$data.btnLoading = true;
          API.loginCavali(
            empresa_ruc, 
            this.$data.formCavali.usuario_cavali, 
            this.$data.formCavali.clave_cavali,
            api_key).then((response) => {
              let res = response.data;
              if(res.status === 200){
                EventBus.$emit("snack-success", res.message);
                this.$store.commit("setEstadoCavali", 2);
              }
            }).catch((e) => {
              EventBus.$emit("snack-error", e.message);
          }).finally(() => {
            this.$data.btnLoading = false;
            this.$data.clave = false;
          })
        }
        else{
          EventBus.$emit("snack-error", 'Debe seleccionar una empresa');
        }
  
      }
    }
  })
  export default class NewPassCavali extends Vue {}
  