




















































import { Component, Vue } from "vue-property-decorator";
// @ts-ignore
import Sunat from "@/views/factoring/misdatos/NewPassSunat.vue"
import Cavali from "@/views/factoring/misdatos/NewPassCavali.vue"
// @ts-ignore
import { Tabs, TabItem } from 'vue-material-tabs';
import {
  TitleTemplateDashboard,
  SelectedCountry,
  RedCapital,
} from "@/configuration";
import { mapState } from "vuex";
import { EventBus } from "@/main";
import API from '@/api';
@Component({
  metaInfo: {
		title: "Mis clavees",
		titleTemplate: TitleTemplateDashboard,
		htmlAttrs: {
			// @ts-ignore
			lang: RedCapital[SelectedCountry].Lang,
		},
	},
  data(){
    return{
      canShow:false,
      businessData: null,
    }

  },
  computed: {
    ...mapState(["user", "setOpenMenuPanel"]),
    setOpenMenuPanel(){
				return this.$store.state.openMenuPanel;
			},
    
  },
  watch:{
    "$store.state.businesSelected": {
      handler(){
        if(this.$store.state.businesSelected != null){
          this.$data.canShow = true;
        }
        else{
          this.$data.canShow = false;
        }
      },
      immediate: true,
      deep:true
				
    },
  },
  methods:{
    bussinessSelection() {
      this.$router.push("/panel-facto/empresas");
    }
  },
  components:{
      Sunat,
      Tabs,
      TabItem,
      Cavali
  }

})
export default class Mypass extends Vue {}
