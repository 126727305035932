





































































import { Component, Vue } from "vue-property-decorator";
import { mapState } from "vuex";
import {
  RedCapital,
  SelectedCountry,
  TitleTemplateDashboard
} from "@/configuration";
import API from "@/api";
import ReusableButton from "@/components/ReusableButton.vue";

@Component({
  metaInfo: {
    title: "Cuenta bancaria",
    titleTemplate: TitleTemplateDashboard,
    htmlAttrs: {
      // @ts-ignore
      lang: RedCapital[SelectedCountry].Lang
    }
  },
  computed: {
    ...mapState(["user", "setOpenMenuPanel"]),
    setOpenMenuPanel(){
      return this.$store.state.openMenuPanel;
    }
  },
  data() {
    return {
      canShow: false,
      oldBankAccount: null,
      bankAccount: null,
      banks: null,
      accountTypes: null,
      btn: {
        clickable: false,
        loading: false,
        isOk: null,
        successText: "Guardado correctamente",
        errorText: "Error interno"
      },
      rules: {
        bankAccountNumber: [
          (v: string) => !!v || "Este campo es requerido",
          (v: string) =>
            (v && v.length >= 7) || "Tiene que ser mayor o igual a 7"
        ]
      }
    };
  },
  watch: {
    bankAccount: {
      handler(val) {
        // @ts-ignore
        if (this.$refs.form.validate()) {
          // @ts-ignore
          this.$data.btn.clickable = this.hasChange();
        } else {
          this.$data.btn.clickable = false;
        }
      },
      deep: true
    }
  },
  async beforeMount() {

    this.$data.banks = await API.getBanks();
    this.$data.accountTypes = await API.getAccountTypes();
    this.$data.canShow = true;

     API.getUserBank(this.$store.state.user.id)
        .then((bankAccount: any) => {
          let temp1 = {};
          let temp2 = {};

          console.log(bankAccount)

          Object.assign(temp1, bankAccount);
          Object.assign(temp2, bankAccount);

          this.$data.oldBankAccount = temp1;
          this.$data.bankAccount = temp2;
          // @ts-ignore
          this.$data.hasChanged = this.hasChange();
        })
        .catch((bankAccount:any) => {
          let temp1 = {};
          let temp2 = {};
          console.log(bankAccount)

          if (bankAccount == null) {
            bankAccount = {
              numero: ""
              // banco_id: 1,
              // tipo_cuenta_id: 1,
            };
          }
          
          Object.assign(temp1, bankAccount);
          Object.assign(temp2, bankAccount);

          this.$data.oldBankAccount = temp1;
          this.$data.bankAccount = temp2;
        })
  },
  methods: {
    async resetBankData() {
      API.getUserBank(this.$store.state.user.id)
        .then((bankAccount: any) => {
          let temp1 = {};
          let temp2 = {};


          Object.assign(temp1, bankAccount);
          Object.assign(temp2, bankAccount);

          this.$data.oldBankAccount = temp1;
          this.$data.bankAccount = temp2;
          // @ts-ignore
          this.$data.hasChanged = this.hasChange();
        })
        .catch(bankAccount => {
          let temp1 = {};
          let temp2 = {};

          if (bankAccount == null) {
            bankAccount = {
              numero: ""
              // banco_id: 1,
              // tipo_cuenta_id: 1,
            };
          }

          Object.assign(temp1, bankAccount);
          Object.assign(temp2, bankAccount);

          this.$data.oldBankAccount = temp1;
          this.$data.bankAccount = temp2;
        });
    },
    hasChange() {
      let oldAccount = Object.values(this.$data.oldBankAccount);
      let newAccount = Object.values(this.$data.bankAccount);

      let hasSomethingChange = false;

      for (let i in oldAccount) {
        if (oldAccount[i] != newAccount[i]) {
          if (newAccount[i] == "") {
            continue;
          }
          hasSomethingChange = true;
        }
      }

      return hasSomethingChange;
    },
    async saveBankAccount() {
      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.$data.btn.loading = true;
        let result: any = await API.updateBankAccount(
          this.$data.bankAccount.numero,
          this.$data.bankAccount.banco_id,
          this.$data.bankAccount.tipo_cuenta_id
        );

        if (result.message != undefined) {
          // @ts-ignore
          this.resetBankData();
          this.$data.btn.isOk = true;
        } else {
          this.$data.btn.isOk = false;
        }

        this.$data.isWorking = true;
      }
    }
  },
  components: {
    ReusableButton
  }
})
export default class BankAccount extends Vue {}
